import service from "@/api/service";
import { GetRequest } from "@/api/talk-comment-list/request";
import { GetResponse } from "@/api/talk-comment-list/response";

/**
 * トーク情報トークコメント取得APIをコールします。
 *
 * @param getRequest トーク情報トークコメント取得のリクエストボディ
 * @return GetResponse
 */
export async function search(getRequest: GetRequest) {
  const response = await service.post("/talk-comment-list", getRequest);
  return response.data as GetResponse;
}
