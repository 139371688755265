import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as TalkCommentAPI from "@/api/talk-comment";
import { CommonResponse, isSuccess } from "@/api/response";
import { DeleteRequest } from "@/api/talk-comment/request";

const MODULE_NAME = "talk-comment/delete";

/**
 * コメント削除APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Delete extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  @MutationAction
  async deleteOne(deleteRequest: DeleteRequest) {
    const commonResponse = await TalkCommentAPI.deleteOne(deleteRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Delete);
