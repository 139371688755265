import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { Route } from "vue-router/types/router";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
// 20210115 NEW_DEV-743 cyber 肖 start
import UIAlertMessage from "@/components/UIAlertMessage.vue";
// 20210115 NEW_DEV-743 cyber 肖 end
import { GetRequest } from "@/api/talk-comment-list/request";
import { DeleteRequest as TalkDeleteRequest } from "@/api/talk/request";
import {
  DeleteRequest as CommentDeleteRequest,
  PostRequest
} from "@/api/talk-comment/request";
import TalkCommentListGet, { ListItem } from "@/store/talk-comment-list/get";
import TalkDelete from "@/store/talk/delete";
import TalkCommentDelete from "@/store/talk-comment/delete";
import TalkCommentPost from "@/store/talk-comment/post";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
// 20210115 NEW_DEV-743 cyber 段 start
import emojiRegex from 'emoji-regex';
// 20210115 NEW_DEV-743 cyber 段 end
const UNREAD_CLASS = "unread";
// 20210115 NEW_DEV-743 cyber 肖 start
@Component({ components: { UIDialogDelete, UIAlertMessage } })
// 20210115 NEW_DEV-743 cyber 肖 end
export default class Comment extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  talkId!: string;

  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------

  // ------------

  // ------------
  // 変動値
  // ------------

  // トーク削除ダイアログの表示有無
  showTalkDialog = false;

  // 削除されるトーク名
  deletingTalkName = "";

  // 削除されるトークID
  deletingTalkId = "";

  // コメント削除ダイアログの表示有無
  showCommentDialog = false;

  // 削除されるコメント
  deletingCommentName = "";

  // 削除されるコメントID
  deletingCommentId = "";

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  };

  isShowDeleteBtn = false;

  postComment = "" as string;
  // 20210122 NEW_DEV-743 cyber 肖 start
  timer = null as number | null;

  new_data = false;

  new_success_flg = false;

  failed = 0;

  deleteTalkCommentFlg = false;

  deleteTalkFlg = false;

  alertMessage = '';

  alertTitle = '';
  // メッセージダイアログの表示有無
  showAlertMessage = false;

  rows_num = 1;

  isUserDel = false;

  textBg = '';
  // 障害対応 cyber start
  old_scroll_height = 0;

  scroll_top = 0;

  userChangFlg = 0;
  // 障害対応 cyber end
  // 20210122 NEW_DEV-743 cyber 肖 end

  // 最新ポストトークフラグ
  new_post_data = 1;

  post_btn_disabled = false;
  // ------------

  /**
   * リストに表示するアイテムリスト
   */
  get listItems() {
    return TalkCommentListGet.getItems;
  }

  /**
   * ユーザID
   */
  get userId() {
    if (this.$route.query.user_id) {
      return this.$route.query.user_id;
    }
    return "";
  }

  /**
   * ユーザ名
   */
  get userName() {
    if (this.$route.query.user_name) {
      return this.$route.query.user_name;
    }
    return "";
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await this.fetchTalkCommentList();
    // 20210107 NEW_DEV-743 cyber 肖 start
    this.loopsiloop(60000);
    // 20210107 NEW_DEV-743 cyber 肖 end
  }

  // 障害対応 cyber start
  async updated() {
    this.scrollToBottom();
  }
  // 障害対応 cyber end

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await TalkCommentListGet.clearResponse();
    await TalkDelete.clearResponse();
    await TalkCommentDelete.clearResponse();
    // 20210107 NEW_DEV-743 cyber 肖 start
    clearTimeout(this.timer);
    this.timer = null;
    // 20210107 NEW_DEV-743 cyber 肖 end
  }

  /**
   * $routeのウォッチャー
   *
   * @param newRoute 遷移先のRoute
   * @param oldRoute 遷移元のRoute
   */
  @Watch("$route")
  watchRouter(newRoute: Route, oldRoute: Route) {
    this.fetchTalkCommentList().then();
  }
  
  // 20210118 NEW_DEV-743 cyber 肖 start
  /**
   * リストに表示するアイテムリストのウォッチャー
   */
  @Watch("listItems")
  async listItemsChange() {
    if(this.listItems.length == 0) {
      this.deleteTalkFlg = false;
    }else {
      //障害対応 cyber start
      this.talkId = this.listItems[0]._item.talkId;
      //障害対応 cyber end
      this.deleteTalkFlg = true;
    }
    this.deleteTalkCommentFlg = false;
    for(let i in this.listItems) {
      if(this.listItems[i].isUser){
        this.deleteTalkCommentFlg = true;
      }
      // 20210122 NEW_DEV-743 cyber 肖 start
      if(this.listItems[this.listItems.length-1].isDel) {
        this.isUserDel = true;
        this.textBg = "background-color: #f1f1f1";
      } else {
        this.isUserDel = false;
        this.textBg = "";
      }
      // 20210122 NEW_DEV-743 cyber 肖 end
    }
    if(!this.deleteTalkCommentFlg){
      this.isShowDeleteBtn = false;
    }
  }

  @Watch("postComment")
  async postCommentChange() {
    this.rows_num = this.postComment.split('\n').length;
  }

  // 障害対応 cyber start
  @Watch("userId")
  async userIdChange() {
    this.userChangFlg = 2;
  }
  // 障害対応 cyber end

  // 20210118 NEW_DEV-743 cyber 肖 end

  /**
   * コメントに適用させるクラスを返却する
   *
   * @param item 選択されたAdminItem
   */
  commentClass(item: ListItem) {
    if (item.isRead) {
      return "";
    } else {
      return UNREAD_CLASS;
    }
  }

  /**
   * トーク削除ボタンが押下された際のコールバック
   */
  async talkDeleteCallback() {
    this.showTalkDialog = true;
    this.deletingTalkId = this.talkId;
    this.deletingTalkName = `${this.userName} ID：${this.userId}`;
  }

  /**
   * コメント削除ボタンが押下された際のコールバック
   */
  async commentDeleteCallback(item: ListItem) {
    this.showCommentDialog = true;
    this.deletingCommentId = item.id;
    this.deletingCommentName = item.content;
  }

  /**
   * トーク削除ダイアログの削除のコールバック
   */
  async doDeleteTalkOnDialog() {
    await Flash.clear();
    this.showTalkDialog = false;
    if (!this.deletingTalkId) {
      return;
    }

    this.isLoading = true;
    await TalkDelete.deleteOne({
      talkId: Number(this.deletingTalkId)
    } as TalkDeleteRequest);
    this.isLoading = false;
    if (TalkDelete.isSuccess) {
      // 20210113 NEW_DEV-743 cyber 肖 start
      await this.redirectWithSuccessAlert(
        "トークを削除しました。",
        `/communication/${this.id}/talk`
      );
      // 20210113 NEW_DEV-743 cyber 肖 end
    } else {
      await Flash.setErrorNow({
        message: TalkDelete.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * コメント削除ダイアログの削除のコールバック
   */
  async doDeleteCommentOnDialog() {
    await Flash.clear();
    this.showCommentDialog = false;
    if (!this.deletingCommentId) {
      return;
    }

    this.isLoading = true;
    await TalkCommentDelete.deleteOne({
      talkCommentId: Number(this.deletingCommentId)
    } as CommentDeleteRequest);
    this.isLoading = false;
    if (TalkCommentDelete.isSuccess) {
      await this.fetchTalkCommentList(false);
    } else {
      await Flash.setErrorNow({
        message: TalkCommentDelete.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * コメントリストの取得
   */
  async fetchTalkCommentList(needScroll: boolean = true) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await TalkCommentListGet.get(this.createRequest());
    this.isLoading = false;
    if (!TalkCommentListGet.isSuccess) {
      await Flash.setErrorNow({
        message: TalkCommentListGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    // this.deleteBtnAct();
  }

  /**
   * コメント送信
   */
  async onPostComment() {
    // 20210127 NEW_DEV-743 cyber 李 start
    if (!this.postComment.trim()) return;
    this.post_btn_disabled = true;
    // 20210127 NEW_DEV-743 cyber 李 end
    // 20210121 NEW_DEV-743 cyber 段 start
    const regex = emojiRegex();
    let match = regex.exec(this.postComment);
    if(match) {
      this.showAlertMessage = true;
      this.alertTitle = '投稿に失敗しました';
      this.alertMessage = '絵文字はご利用になれません。';
      // 20210126 NEW_DEV-743 cyber 李 start
      this.post_btn_disabled = false;
      // 20210126 NEW_DEV-743 cyber 李 end
      return;
    }
    // 20210121 NEW_DEV-743 cyber 段 end
    // 20210121 NEW_DEV-743 cyber 李 start
    const request = {
      shopId: this.id,
      userId: this.userId,
      //NEW_DEV-1505 cyber start
      content: this.postComment
      //NEW_DEV-1505 cyber end
    } as PostRequest;
    // 20210121 NEW_DEV-743 cyber 李 end
    await TalkCommentPost.post(request);
    if (TalkCommentPost.isSuccess) {
      this.postComment = "";
      this.new_post_data = 1;
      await this.fetchTalkCommentList();
    } else {
      await Flash.setErrorNow({
        message: TalkCommentDelete.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    // 20210126 NEW_DEV-743 cyber 李 start
    this.post_btn_disabled = false;
    // 20210126 NEW_DEV-743 cyber 李 end
  }

  /**
   * ファイル送信
   *
   * @param event Event
   */
  async onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      // 20210115 NEW_DEV-743 cyber 肖 start
      let file_name = target.files[0].name;
      let re = /^[.0-9a-zA-Z_-]+$/;
      if (target.files[0].size === 0 || !re.test(file_name)) {
        this.showAlertMessage = true;
        this.alertTitle = '投稿に失敗しました';
        this.alertMessage = '画像ファイルを半角英数字に変更の上、投稿してください。';
        return;
      }
      if(!this.isImage(target.files[0])){
        return;
      };
      // 20210115 NEW_DEV-743 cyber 肖 end
      const request = {
        shopId: this.id,
        userId: this.userId,
        file: target.files[0]
      } as PostRequest;
      target.value = "";
      await TalkCommentPost.post(request);
      if (TalkCommentPost.isSuccess) {
        await this.fetchTalkCommentList();
        this.new_post_data = 1;
      } else {
        await Flash.setErrorNow({
          message: TalkCommentDelete.getMessage,
          showReloadButton: false
        } as ErrorAlert);
      }
    }
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest() {
    // 20210118 NEW_DEV-743 cyber 李 start
    return {
      talkId: Number(this.talkId),
      shopId: Number(this.id),
      userId: Number(this.userId)
    } as GetRequest;
    // 20210118 NEW_DEV-743 cyber 李 end
  }

  /**
   * 最初の未読コメントまでスクロールさせる
   */
  static scrollToUnreadTop() {
    const elements = document.getElementsByClassName(UNREAD_CLASS);
    if (elements.length <= 0) {
      return;
    }
    elements[0].scrollIntoView(true);
  }

  // 20210118 NEW_DEV-743 cyber 肖 start
  async loopsiloop(interval: Number) {
    this.timer = setTimeout(() => {
      this.new_data = true;
      this.fetchTalkCommentList();
      if(this.new_success_flg){
        interval = 60000;
        this.loopsiloop(interval);
      }else{
        if(++this.failed < 10){
          interval = interval + 1000;
          this.loopsiloop( interval );
        }
      }
    }, interval);
  }

  isImage(file: File) {
    let pos = file.name.lastIndexOf(".");
    if (pos === -1) return false;
    let tyep = file.name.slice(pos + 1).toLowerCase();
    if(tyep === "jpeg" || tyep === "jpg" || tyep === "png" || tyep === "gif" || tyep === "pdf") {
      return true;
    };
    this.showAlertMessage = true;
    this.alertTitle = '投稿に失敗しました';
    this.alertMessage = '画像ファイルを半角英数字に変更の上、投稿してください。';
    return false;
  }

  pdfFormat(url: String) {
    let url_arr = url.split('/');
    let name_arr = url_arr[url_arr.length-1].split('_');
    name_arr.shift();
    return name_arr.join('_');
  }

  // 最新トーク底部
  scrollToBottom() {
    this.$nextTick(() => {
      if(this.userChangFlg == 1 || this.new_post_data == 1) {
        document.getElementById('comment_box').scrollTop = document.getElementById('comment_box').scrollHeight;
        this.new_post_data = 0;
      }
      this.userChangFlg--;
    });
  }
  // 20210118 NEW_DEV-743 cyber 肖 end
}
