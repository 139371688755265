import service from "@/api/service";
import { DeleteRequest, PostRequest } from "@/api/talk-comment/request";
import { CommonResponse } from "@/api/response";

/**
 * コメント削除APIをコールします。
 *
 * @param deleteRequest コメント削除のリクエストボディ
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-talk-comment", deleteRequest);
  return response.data as CommonResponse;
}

export async function post(postRequest: PostRequest) {
  //NEW_DEV-1505 cyber start
  if (postRequest.file) {
    const formData = new FormData();
    formData.append("shop_id", postRequest.shopId.toString());
    formData.append("user_id", postRequest.userId.toString());
    formData.append("file", postRequest.file);
    if (isPDFExtension(postRequest.file)) {
      formData.append("content_type", "5");
    }else {
      formData.append("content_type", "2");
    }
    const response = await service.postMultipart("/talk-comment-post", formData);
    return response.data as CommonResponse;
  } else {
    let request = { 
      shop_id: postRequest.shopId.toString(),
      user_id: postRequest.userId.toString(),
      content: postRequest.content!!.toString(),
      content_type: 1,
    };
    const response = await service.postMultipart("/talk-comment-post", request);
    return response.data as CommonResponse;
  }
  //NEW_DEV-1505 cyber end
}

function isPDFExtension(file: File): boolean {
  let pos = file.name.lastIndexOf(".");
  if (pos === -1) return false;
  return file.name.slice(pos + 1).toLowerCase() === "pdf";
}