import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse, GetResult } from "@/api/talk-comment-list/response";
import { GetRequest } from "@/api/talk-comment-list/request";
import * as TalkCommentListAPI from "@/api/talk-comment-list";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "talk-comment-list/get";

/**
 * トークコメントリスト一覧のデコレーター
 */
export class ListItem {
  constructor(private _item: GetResult) {}
  get id() {
    return this._item.id;
  }
  get content() {
    return this._item.content;
  }
  get time() {
    // 20210107 NEW_DEV-743 cyber 肖 start
    const d = new Date(this._item.createDate);
    // 20210107 NEW_DEV-743 cyber 肖 end
    return `${d.getHours()}:${("00" + d.getMinutes()).substr(-2)}`;
  }
  get borderDate() {
    // 20210107 NEW_DEV-743 cyber 肖 start
    const d = new Date(this._item.createDate);
    // 20210107 NEW_DEV-743 cyber 肖 end
    const wDay = ["日", "月", "火", "水", "木", "金", "土"][d.getDay()];
    return `${d.getFullYear()}/${ListItem.formatDate(
      d.getMonth() + 1
    )}/${ListItem.formatDate(d.getDate())}（${wDay}）`;
  }
  get isUser() {
    // 20201216 NEW_DEV-743 cyber 肖 start
    // return this._item.userId.length > 0;
    return this._item.adminMemberId;
    // 20201216 NEW_DEV-743 cyber 肖 end
  }
  get isBorder() {
    return this._item.borderFlg === 1;
  }
  get isImage() {
    return this._item.contentType === 2;
  }
  get isPDF() {
    return this._item.contentType === 5;
  }
  // 20210120 NEW_DEV-743 cyber 肖 start
  get isMovie() {
    return this._item.contentType === 6;
  }
  get isDel() {
    return this._item.contentType === 99;
  }
  // 20210120 NEW_DEV-743 cyber 肖 end
  get isRead() {
    return this._item.readFlg === 1;
  }
  get isDeleted() {
    return this._item.adminDelFlg === 1;
  }

  static formatDate(val: number) {
    return ("00" + val).substr(-2);
  }
}

/**
 * トークリスト取得API（/talk-user-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.map(v => new ListItem(v));
    } else {
      return [];
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const getResponse = await TalkCommentListAPI.search(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
