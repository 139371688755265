import service from "@/api/service";
import { DeleteRequest } from "@/api/talk/request";
import { CommonResponse } from "@/api/response";

/**
 * トーク削除APIをコールします。
 *
 * @param deleteRequest トーク削除のリクエストボディ
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-talk", deleteRequest);
  return response.data as CommonResponse;
}
